// Get all of the backend annotator configuration necessary to correctly submit a job
angular.module('formConfig', ['sq.config', 'ngCookies'])
.factory('annotatorArguments',
function annotatorArgumentsFactory($q, $cookieStore, $http, SETTINGS, $log) {
  var annotatorArgs = $cookieStore.get('annotatorArguments');

  $log.debug('found value in cookieStore?: ', !!annotatorArgs);
  
  function getArgs() {
    var deferred = $q.defer();
    
    if (annotatorArgs) {
      deferred.resolve(annotatorArgs);
    } else {
      $http.get(SETTINGS.formGetArgsUrl).success(function successCb(jsonData) {
        $cookieStore.put( 'annotatorArguments', angular.fromJson(jsonData) );
        deferred.resolve( angular.fromJson(jsonData) );
      })
      .error(function errorCb(data) {
        deferred.reject(data);
        $log.error('failed to get data from ' + SETTINGS.formGetArgsUrl, data);
      });
    }

    return deferred.promise;
  }
  return { getArgs: getArgs };
});

